<template>
  <tt-info v-if="baseInfo && categoryItems" :menu="menu"></tt-info>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "infoPage",
  computed: {
    ...mapState({
      baseInfo: (state) => state.baseInfo,
      categoryItems: (state) => state.categoryItems,
    }),
    menu() {
      return this.categoryItems.find(
        (item) => item.id === this.$route.params.mid
      );
    },
  },
};
</script>
